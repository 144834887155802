<template>
 <div class="not-found-page-content">
  <img alt="404" src="@/assets/images/404.png" />
   <div class="text">
     Кажется  что-то пошло не так! страница, которую вы запрашиваете, не существует.  Возможно она устарела, была удалена, или был введен неверный адрес в адресной строке.
   </div>
   <BaseButton
     label="Перейти к выбору заявки"
     @clicked="pushToMainPage()"
   />
 </div>
</template>

<script src="./PageNotFound.ts" lang="ts"/>
<style src="./PageNotFound.scss" lang="scss" />
