import { defineComponent } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PageNotFound',

  components: {
    BaseButton
  },

  setup() {
    const { push } = useRouter();
    const pushToMainPage = () => {
      push('/');
    };

    return {
      pushToMainPage
    };
  }
});
